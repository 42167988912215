<template>
  <v-dialog v-model="dialog" width="1200" hide-overlay @click:outside="close" @keydown.esc="close">
    <v-toolbar flat dark color="primary">
      <v-btn icon dark @click="close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Configurar build massivo</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card ripple class="pa-6 mx-auto">
      <v-container>
        <v-progress-linear v-if="loading" indeterminate color="primary" />
        <div v-else>
          <h2 align="center" class="ma-4">
            Atenção! Todos os aplicativos abaixo serão enviados para fila com as propriedades informadas. Deseja continuar?
            <br />
            Total de apps: {{ total }}
          </h2>
          <v-row v-if="apps" no-gutters class="ma-4">
            <v-chip v-for="(app, index) in apps" :key="index" close class="my-2 mx-auto" @click:close="popApp(index)">{{
              app.channels.app.build.app_name
            }}</v-chip>
          </v-row>
        </div>
        <v-card-actions class="pt-2 pb-0">
          <v-btn :disabled="loading" class="mb-2 mr-2" block color="error" @click="massiveBuild"
            ><v-icon class="mr-2">mdi-alert-circle</v-icon> Continuar</v-btn
          >
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_MASSIVE_BUILD, QUERY_GET_MASSIVE_BUILD_LIST } from '@/modules/app/graphql/index.js'

export default {
  props: {
    dialog: {
      type: Boolean,
      default: () => false
    },
    platforms: {
      type: Array,
      default: () => []
    },
    buildParams: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    loading: false,
    apps: [],
    total: 0
  }),
  apollo: {
    massiveBuildList: {
      query: QUERY_GET_MASSIVE_BUILD_LIST,
      fetchPolicy: 'no-cache',
      update({ massiveBuildList }) {
        this.apps = massiveBuildList
        this.total = massiveBuildList?.length
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['app_build']
        }
      }
    }
  },
  methods: {
    close() {
      this.cleanFields()
      this.refetch()
      this.$emit('update:dialog', false)
    },
    cleanFields() {
      this.apps = []
      this.total = 0
    },
    refetch() {
      this.$apollo.queries.massiveBuildList.refetch()
    },
    popApp(index) {
      this.apps.splice(index, 1)
      this.total = this.apps.length
    },
    getIds(appsList) {
      return appsList.map(app => app._id)
    },
    async massiveBuild() {
      this.$emit('update:build_params.workflow_id', `create_${this.platforms[0]}`)
      this.buildParams.workflow_id = `create_${this.platforms[0]}`
      const app_ids = this.getIds(this.apps)

      try {
        this.loading = true
        await this.$apollo.mutate({
          mutation: MUTATION_MASSIVE_BUILD,
          variables: {
            build_params: this.build_params,
            apps: {
              app_ids
            }
          },
          context: {
            headers: {
              authorization: localStorage.getItem('session_id')
            },
            uri: this.$microservicesUrls['app_build']
          }
        })

        this.$snackbar({ message: 'Build massivo realizado com sucesso', snackbarColor: '#2E7D32' })
        this.loading = false
        this.close()
        this.$router.push('/app-logs')
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao realizar build massivo de apps', snackbarColor: '#F44336' })
      }
    }
  }
}
</script>
